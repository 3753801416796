import React, { useEffect, useState } from 'react';
import Request from "../core/httpClient";

const request = new Request();

const Pedido = (props) => {

    const [loading, setLoading] = useState(true);
    const [pedido, setPedido] = useState();
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    useEffect(() => {

        if (props.match.params) {
            fetchOrder(props.match.params)
        };

    }, []);

    const fetchOrder = async (params) => {

        try {

            setLoading(true);

            const response = await request.post("/restaurantes/get/databytoken", { token: params.id });

            if (response.pedido) {

                response.pedido.orden = JSON.parse(response.pedido.orden);

                setPedido(response.pedido);
            };

            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error("Error fetching order:", error);
        };
    };

    const UpdateStatus = async (status) => {
        try {

            setLoadingConfirm(true);

            const { id_inquilino, id_pedido, id_restaurante_administracion } = pedido;

            const data = { id_inquilino, id_pedido, id_restaurante_administracion };

            const response = await request.post("/restaurantes/confirmar/entrega", data);

            if (response.updated) {
                pedido.estado = 'Entregado';
                setPedido(pedido);
            };

            setLoadingConfirm(false);

        } catch (error) {
            setLoadingConfirm(false);
            console.error("Error Update Status order:", error);
        };
    };

    return (
        <div className="invitation">
            <div className="justify-center">
                <div className="container column">
                    <div className="white-space-16"></div>
                    <div className="justify-center align-center">
                        {loading ? (
                            <div className="column">
                                <div className="justify-center">
                                    <i className="fas fa-spinner fa-2x fa-spin"></i>
                                </div>
                                <div className="white-space-16"></div>
                                <p className="text-center font-regular weight-medium loading-text">
                                    Cargando orden de entrega
                                </p>
                            </div>
                        ) : (
                            <>
                                {pedido && (
                                    <React.Fragment>
                                        <div className="justify-center column">
                                            <div className="justify-center">
                                                <p className="text-center font-regular uppercase">
                                                    <b className="font-regular">
                                                        Restaurante
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="white-space-4" />
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b className="text-center">{pedido.restaurante}</b>
                                                </p>
                                            </div>
                                            <div className="white-space-24" />
                                            <div className="justify-center">
                                                <p className="text-center font-regular uppercase">
                                                    <b className="font-regular">
                                                        Entrega
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="white-space-4" />
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b>Fraccionamiento: {pedido.fraccionamiento}</b>
                                                </p>
                                            </div>
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b>Subfracionamiento: {pedido.subfraccionamiento}</b>
                                                </p>
                                            </div>
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b>Vivienda: {pedido.vivienda}</b>
                                                </p>
                                            </div>
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b>Residente: {pedido.inquilino}</b>
                                                </p>
                                            </div>
                                            <div className="justify-center">
                                                <p className="text-center">
                                                    <b>Telefono: +{pedido.telefono}</b>
                                                </p>
                                            </div>
                                            <div className="white-space-24" />
                                            <div className="justify-center">
                                                <p className="text-center font-regular uppercase">
                                                    <b className="font-regular">
                                                        Orden
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="white-space-4" />
                                            <div className="justify-center">
                                                <div className="table-content column">
                                                    <div className="table-content row">
                                                        <p className="text-center uppercase table-title">Plato</p>
                                                        <p className="text-center uppercase table-title">Cantidad</p>
                                                    </div>
                                                    {pedido.orden.map((platillo) => (
                                                        <div key={platillo.id_linea_pedido} className="table-content row">
                                                            <p className="text-center">{platillo.plato}</p>
                                                            <p className="text-center">{platillo.cantidad}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="white-space-24" />
                                            <div className="justify-center">
                                                <p className="text-center font-regular uppercase">
                                                    <b className="font-regular">
                                                        Pago
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="white-space-4" />
                                            <div className="justify-center">
                                                <p className="text-left align-center">
                                                    &nbsp;&nbsp;<i className="fas fa-money-bill" />&nbsp;
                                                    <b>
                                                        &nbsp;{pedido.metodo_pago}
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="justify-center">
                                                <p className="text-left align-center">
                                                    &nbsp;&nbsp;&nbsp;<i className="fas fa-receipt" />&nbsp;&nbsp;&nbsp;
                                                    <b>
                                                        Total:&nbsp; ${pedido.total.toFixed(2)}
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="white-space-32" />
                                            <div className="justify-center">
                                                {pedido.estado === 'Entregado' ? (
                                                    <div className="justify-center">
                                                        Pedido entregado &nbsp; <i className="fas fa-check-circle" />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => !loadingConfirm && UpdateStatus(5)}
                                                    >
                                                        {loadingConfirm ?
                                                            <div className='flex justify-center align-center full'>
                                                                <i className="fas fa-spinner fa-spin"></i>
                                                            </div>
                                                            :
                                                            'CONFIRMAR ENTREGA'
                                                        }
                                                    </button>
                                                )}
                                            </div>
                                            <div className="white-space-16" />
                                        </div>
                                    </React.Fragment>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pedido;